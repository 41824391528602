import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useContext, useState } from "react";
import { HiMenu } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { LoaderContext } from "../../context/LoaderContext";
import { get_google_profile, login } from "../../apis/auth";
import { get_profile } from "../../apis/user";
import { UserContext } from "../../context/UserContext";
import { PopUpContext } from "../../context/PopUpContext";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineUser } from "react-icons/ai";

export default function InnovexNavbar({ windowSize, onClick }) {
  const [isMenu, setIsMenu] = useState(false);

  const { tokenState, userState } = useContext(UserContext);
  const [token, setToken] = tokenState;
  const [userDetails, setUserDetails] = userState;

  const { popUpState, popUpContentState } = useContext(PopUpContext);
  const [popUp, setPopUp] = popUpState;
  const [popUpContent, setPopUpContent] = popUpContentState;

  const { loadingState } = useContext(LoaderContext);
  const [loading, setLoading] = loadingState;
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const navigate = useNavigate();
  const loginIEDC = async (credentialResponse, b) => {
    setLoading(true);
    const google_profile_response = await get_google_profile(
      credentialResponse.access_token
    );
    if (google_profile_response.status === 200) {
      const response = await login(credentialResponse.access_token);
      if (response.status === 200) {
        setToken(response.response.key);
        // setPopUpContent({ heading: "NOTICE", description: "Login successfull" })
        // setPopUp(true);
        const profile_response = await get_profile(response.response.key);
        // setUserDetails(google_profile_response)

        // let userObj={ full_name: google_profile_response.response.names[0].displayName, email_id: google_profile_response.response.emailAddresses[0].value, profile_pic: google_profile_response.response.photos[0].url,profile_completed:profile_response.response.profile_completed }
        // localStorage.setItem('IEDCUser', JSON.stringify(userObj))
        if (profile_response.status === 200) {
          localStorage.setItem("IEDCUserToken", response.response.key);
          setUserDetails(profile_response.response.data);
          // console.log(profile_response.response.data)
          if (profile_response.response.data.profile_completed == false){
            navigate("innovex/edit-profile")
          }


          setPopUpContent({
            heading: "NOTICE",
            description: "Login successful",
          });
          setPopUp(true);
          //navigate("/dashboard");
        }
      } else {
        setPopUpContent({
          heading: "Login failed",
          description: "Login failed.",
        });
        setPopUp(true);
      }
    } else {
      setPopUpContent({
        heading: "Login failed",
        description: "Login failed.",
      });
      setPopUp(true);
    }
    setLoading(false);
  };

  const getGoogleAccount = useGoogleLogin({
    onSuccess: loginIEDC,
    redirect_uri: "http://localhost:3000/",
    scope: "https://www.googleapis.com/auth/userinfo.profile",
    onError: () => {},
  });

  const handleLogout = async () => {
    setLoading(true);
    googleLogout();
    localStorage.setItem("IEDCUser", "");
    localStorage.setItem("IEDCUserToken", "");
    setUserDetails(null);
    setToken("");
    navigate("");
    setPopUpContent({ heading: "", description: "Logout successful" });
    setPopUp(true);

    setLoading(false);
  };

  return (
    <header className="absolute top-10 left-0 z-[100] text-white w-full flex justify-between items-center bg-transparent p-3 sm:p-5 sm:px-10 lg:px-20 font-Raleway font-bold">
      {/* <div className="text-white font-bold text-lg">Moonex</div> */}
      {/* <img src="logo.png" className="w-32 sm:w-60 z-50"></img> */}
      <nav className="hidden md:flex items-center justify-center w-full gap-10 lg:gap-20 z-50 ">
        <span
          className="relative  group cursor-pointer"
          onClick={() => navigate("")}
        >
          Home
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>

        <span
          className="relative  group cursor-pointer"
          onClick={() => onClick("Workshop")}
        >
          Workshops
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        <span
          className="relative  group cursor-pointer"
          onClick={() => onClick("Talk Session")}
        >
          Talk Sessions
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        <span
          className="relative group cursor-pointer"
          onClick={() => onClick("Competition")}
        >
          Competitions
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        <span
          className="relative  group cursor-pointer"
          onClick={() => navigate("/innovex/contact-us")}
        >
          Contact
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        {token?.length > 0 ? (
          <div
            className="relative flex items-center focus:outline-none cursor-"
            aria-label="toggle profile dropdown"
            onClick={() => setIsProfileOpen(!isProfileOpen)}
          >
            <div
              className={`w-8 h-8 group  overflow-hidden border-2 border-white hover:border-white transition-all cursor-pointer
               rounded-full font-semibold flex justify-center items-center`}
            >
              <AiOutlineUser className="text-white  group-hover:text-white text-xl transition-all" />
            </div>
            <div
              className={`absolute flex flex-col gap-3 top-full w-60 left-0 p-5 rounded-xl bg-black bg-opacity-60 ${
                isProfileOpen ? "" : "hidden"
              }`}
            >
              <button
                className="p-1 px-4 rounded-lg bg-white bg-opacity-30 hover:bg-opacity-20 "
                onClick={() => navigate("reg-events")}
              >
                Registered Events
              </button>
              <button
                className="p-1 px-4 rounded-lg bg-white bg-opacity-30 hover:bg-opacity-20 "
                onClick={() => navigate("edit-profile")}
              >
                Edit Profile
              </button>
              {/* <button
                className="p-1 px-4 rounded-lg bg-white bg-opacity-30 hover:bg-opacity-20 "
                onClick={() => navigate("my-pass")}
              >
                My Pass
              </button> */}
              
              
              <button
                className="p-1 px-4 rounded-lg bg-white bg-opacity-30 hover:bg-opacity-20 "
                onClick={() => handleLogout()}
              >
                Logout
              </button>
            </div>
          </div>
        ) : (
          //   <button onClick={() => getGoogleAccount()}>Login</button>
          <button
            className="relative bg-transparent text-black text-[12px] sm:text-[16px] hover:text-white p-2 sm:px-4 lg:px-6 rounded-full overflow-hidden group border border-white z-50"
            onClick={() => getGoogleAccount()}
          >
            <span className="relative z-10">Login</span>
            <span className="absolute inset-0 bg-white transform -translate-x-0 group-hover:translate-x-full transition-transform duration-300 ease-in-out"></span>
          </button>
        )}
      </nav>
      <div className="flex md:hidden z-50">
        <HiMenu size={30} onClick={() => setIsMenu(!isMenu)} />
      </div>

      <div
        className={` fixed p-5 px-14 flex flex-col items-center justify-evenly h z-[10000] h-screen w-screen top-0 gap-10  transition-all bg-[#071624] ${
          isMenu ? "right-0" : "-right-[500%]"
        }`}
      >
        <div className="flex items-end w-full">
          <IoClose size={30} onClick={() => setIsMenu(!isMenu)} />
        </div>

        {/* <nav className="flex flex-col justify-evenly w-screen h-screen sm:hidden"> */}
        <span
          className="relative  group cursor-pointer"
          onClick={() => {
            setIsMenu(!isMenu);
            navigate("");
          }}
        >
          Home
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>

        <span
          className="relative group cursor-pointer"
          onClick={() => {
            setIsMenu(!isMenu);
            onClick("Workshop");
          }}
        >
          Workshops
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        <span
          className="relative  group cursor-pointer"
          onClick={() => {
            setIsMenu(!isMenu);
            onClick("Talk Session");
          }}
        >
          Talk Sessions
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        <span
          className="relative  group cursor-pointer"
          onClick={() => {
            setIsMenu(!isMenu);
            onClick("Competition");
          }}
        >
          Competitions
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        <span
          className="relative group cursor-pointer"
          onClick={() => {
            setIsMenu(!isMenu);
            navigate("/innovex/contact-us");
          }}
        >
          Contact
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        {/* <span
          className="relative group cursor-pointer"
          onClick={() => {
            setIsMenu(!isMenu);
            navigate("/innovex/my-pass");
          }}
        >
          My pass
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span> */}
        <span
          className="relative group cursor-pointer"
          onClick={() => {
            setIsMenu(!isMenu);
            navigate("/innovex/edit-profile");
          }}
        >
          Edit Profile
          <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
        </span>
        {token?.length > 0 ? (
          <div className="flex flex-col gap-20">
            <span
              className="relative group cursor-pointer"
              onClick={() => {
                setIsMenu(!isMenu);
                navigate("reg-events");
              }}
            >
              Registered Events
              <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span>
            </span>
            
            <button
              className="relative bg-transparent text-black text-[12px] sm:text-[16px] hover:text-white hover:text-yell p-2 px-6 lg:py-3 rounded-full overflow-hidden group border border-white"
              onClick={() => {
                setIsMenu(!isMenu);
                handleLogout();
              }}
            >
              
              <span className="relative z-10">Logout</span>
              <span className="absolute inset-0 bg-white transform -translate-x-0 group-hover:translate-x-full transition-transform duration-300 ease-in-out"></span>
            </button>
            
          </div>
        ) : (
          <button
            className="relative bg-transparent text-black text-[12px] sm:text-[16px] hover:text-white hover:text-yell p-2 px-6 lg:py-3 rounded-full overflow-hidden group border border-white"
            onClick={() => {
              setIsMenu(!isMenu);
              getGoogleAccount();
            }}
          >
            <span className="relative z-10">Login</span>
            <span className="absolute inset-0 bg-white transform -translate-x-0 group-hover:translate-x-full transition-transform duration-300 ease-in-out"></span>
          </button>
        )}
        {/* </nav> */}
      </div>
    </header>
  );
}
