import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
const ContactPage = () => {
  const contacts = [
    {
      name: 'Anna',
      phone: '+91 97442 16224',
    },
    {
      name: 'Nihal',
      phone: '+91 70121 28420',
    },
    {
      name: 'Raja Sethupathi',
      phone: '+91 94002 53994',
    },
    {
      name: 'Safiya',
      phone: '+91 88487 06374',
    }
  ];

  return (
    <div className="min-h-screen bg-[#190024] text-white p-4 md:p-8 ">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-5xl font-bold mb-4 py-20">Contact Us</h1>
          {/* <p className="text-gray-400 text-sm md:text-lg">Get in touch with our team</p> */}
        </div>

        {/* Contact Cards */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {contacts.map((contact, index) => (
            <div 
              key={index}
              className="bg-gray-900 rounded-lg p-6 transition-transform duration-300 hover:transform hover:scale-105"
            >
              <div className="flex items-center gap-4">
                <div className="bg-blue-500 p-3 rounded-full">
                  <PersonIcon className="w-6 h-6" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2">{contact.name}</h3>
                  <a 
                    href={`tel:${contact.phone.replace(/\s+/g, '')}`}
                    className="flex items-center gap-2 text-gray-400 hover:text-blue-400 transition-colors duration-300 text-[#ffd700]"
                  >
                    <PhoneIcon className="w-4 h-4" />
                    <span>{contact.phone}</span>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Additional Contact Info */}
        <div className="mt-12 text-center">
          <div className="inline-flex items-center justify-center gap-2 text-gray-400 hover:text-blue-400 transition-colors duration-300">
            <EmailIcon className="w-5 h-5" />
            <a href="mailto:iedc@tkmce.ac.in" className="text-lg">
              iedc@tkmce.ac.in
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;