import React, { useContext, useState } from 'react'
import { Formik, Form, Field, useField } from 'formik';
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../../context/UserContext';
import useAuthFetch from '../../../hooks/useAuthFetch';
import { BASE_URL } from '../../../constants/urls';
import { edit_profile } from '../../../apis/user';
import { PopUpContext } from '../../../context/PopUpContext';
import LocalLoader from '../../../components/LocalLoader';

function InnovexEditProfile() {
    const { userState, tokenState } = useContext(UserContext);
    const [userDetails, setUserDetails] = userState;
    const [token, setToken] = tokenState;
    const { popUpState, popUpContentState } = useContext(PopUpContext);
    const [popUp, setPopUp] = popUpState;
    const [popUpContent, setPopUpContent] = popUpContentState;

    const [loading, setLoading] = useState(false);

    const { error, isPending, data: profile } = useAuthFetch(BASE_URL + '/api/users/me');
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        setLoading(true);
        const response = await edit_profile(token, values, profile.data.username, profile.data.email_id);
        setLoading(false);
        setPopUp(true);
        if (response.status === 200) {
            setPopUpContent({ heading: "NOTICE", description: "Your profile has been updated successfully!" });
            setUserDetails(response.response);
            const redirectPath = localStorage.getItem("IEDC_redirectPath");
            localStorage.setItem('IEDC_redirectPath', "");
            navigate("/innovex");
        } else {
            setLoading(false);
            alert(JSON.stringify(response.errMssg));
            setPopUpContent({ heading: "NOTICE", description: "Retry updating your profile!" })
        }
    };

    const handleViewPass = () => {
        navigate(`/innovex/my-pass`);
    };

    return (
        <div data-aos="fade-zoom-in" data-aos-duration="1000" className='flex flex-row pb-24 bg-[#190024]'>
            {loading && <LocalLoader />}
            <div className='rounded-md h-fit relative w-full text-db-text-p flex flex-col overflow-x-hidden items-center text-md tracking-wide'>
                <h3 className='w-full my-5 text-left ml-8 text-gray-500'>
                    <span className='text-gray-400'>User</span> / Edit profile
                </h3>
                <div className='w-full md:w-2/3 h-full p-5 relative'>
                    {/* Profile Picture */}
                    <div className="flex justify-center mb-6">
                        <div className="relative">
                            <img 
                                src={profile?.data?.profile_pic || '/default-avatar.png'} 
                                alt="Profile" 
                                className="w-32 h-32 rounded-full object-cover border-4 border-gray-300"
                            />
                        </div>
                    </div>

                    {/* My Pass Button */}
                    {/* <div className="flex justify-center mb-6">
                        <button
                            onClick={handleViewPass}
                            className="bg-purple-600 text-white px-6 py-2 rounded-md hover:bg-purple-700 transition-colors"
                        >
                            My Pass
                        </button>
                    </div> */}

                    <h3 className='text-db-text-h text-xl md:text-2xl text-center'>
                        {userDetails?.profile_completed === false ? "ADD PROFILE" : "EDIT PROFILE"}
                    </h3>

                    {isPending && <div className='p-2'><LocalLoader /></div>}
                    {error && <div className='p-2'>{error}</div>}
                    <Formik 
                        onSubmit={handleSubmit}
                        enableReinitialize={true}
                        initialValues={{
                            name: profile?.data?.name !== null ? profile?.data?.name : "",
                            email_id: profile?.data?.email_id !== null ? profile?.data?.email_id : "",
                            phone_number: profile?.data?.phone_number !== null && profile?.data.phone_number !== undefined ? profile?.data?.phone_number.slice(3) : "",
                            college: profile?.data?.college !== null ? profile?.data?.college : "",
                        }}
                    >
                        {/* Rest of the form remains the same */}
                        {(formProps) => (
                            <Form>
                                <h3 className='mt-3 font-medium'>Name <span className='text-red-400'>*</span></h3>
                                <Field
                                    required={true}
                                    id="name" 
                                    name="name" 
                                    className='bg-transparent outline-none rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border bg-form-bg' 
                                    type="text" 
                                    placeholder="Name" 
                                />

                                <h3 className='mt-3 font-medium'>Phone number<span className='text-red-400'>*</span></h3>
                                <div className='bg-transparent flex flex-row items-center outline-none rounded-md px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border bg-form-bg'>
                                    <p>+91</p>
                                    <Field
                                        required={true} 
                                        id="phone_number" 
                                        name="phone_number" 
                                        className='bg-transparent outline-none rounded-md py-2 px-4 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border bg-form-bg' 
                                        type="number" 
                                        placeholder="" 
                                    />
                                </div>

                                <h3 className='mt-3 font-medium'>College<span className='text-red-400'>*</span></h3>
                                <Field
                                    required={true} 
                                    id="college" 
                                    name="college" 
                                    className='bg-transparent outline-none rounded-md py-2 px-4 mt-2 w-full placeholder-slate-500 placeholder-opacity-50 text-gray-400 outline focus:outline-form-border bg-form-bg' 
                                    type="text" 
                                    placeholder="" 
                                />

                                <div className='mt-7 flex flex-row'>
                                    <button 
                                        type="submit" 
                                        className='text-black font-semibold hover:border-db-text-ph hover:text-white bg-gray-300 hover:bg-db-button-h rounded-md px-3 md:py-1 mr-5'
                                    >
                                        Submit
                                    </button>
                                    <button 
                                        type="reset" 
                                        onClick={Formik.resetForm} 
                                        className='text-db-text-p hover:border-db-text-ph hover:text-white bg-db-button hover:bg-db-button-h rounded-md px-3 md:py-1'
                                    >
                                        Reset
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default InnovexEditProfile;