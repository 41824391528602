import React from 'react';

function NotFound(props) {
    return (
        <div className='flex h-screen w-screen justify-center items-center text-black' data-aos="fade-zoom-in" data-aos-duration="650">
            <span className='text-[20px] sm:text-[40px]'>Page Not Found</span>
        </div>
    );
}

export default NotFound;