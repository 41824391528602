import React from 'react';

const CustomizedTimeline = () => {
  const events = [
    { id: 1, time: "9:30 AM", title: "Inauguration", location: "Auditorium" },
    { id: 2, time: "10:30 AM", title: "Lessons from a Rising Entrepreneur", location: "APJ Hall" },
    { id: 3, time: "11:30 AM", title: "Financial Management Workshop", location: "APJ Hall" },
    { id: 4, time: "10:30 AM - 12:30 PM", title: "Tradecraft", location: "M301" },
    { id: 5, time: "10:00 AM - 12:30 PM", title: "The Profit Equation", location: "EEE Seminar Hall" },
    { id: 6, time: "1:30 PM", title: "The Power of Collaboration (Fulva)", location: "APJ Hall" },
    { id: 7, time: "2:30 PM", title: "Content Creation Workshop", location: "APJ Hall" },
    { id: 8, time: "1:30 PM", title: "Relay Coding", location: "SS Lab" },
    { id: 9, time: "1:30 PM", title: "Dawn", location: "EEE Seminar Hall" },
    { id: 10, time: "1:30 PM", title: "Leadership", location: "SEEDS, APJ Park" },
    { id: 11, time: "4:30 PM", title: "Culturals", location: "APJ Park" }
  ];

  return (
    <div className="min-h-screen w-full bg-[#190024] py-8">
      <div className="max-w-6xl mx-auto px-4">
        <h2 className="text-white font-bold text-4xl md:text-5xl text-center mb-12">
          TIMELINE
        </h2>

        <div className="relative">
          {/* Central line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-[#ffd700]" />

          {events.map((event, index) => (
            <div key={event.id} className="relative mb-8">
              <div className={`flex items-center justify-center md:justify-${index % 2 === 0 ? 'end' : 'start'} w-full md:w-1/2 ${index % 2 === 0 ? 'md:mr-auto' : 'md:ml-auto'}`}>
                {/* Circle marker - hidden on mobile */}
                <div className="absolute left-1/2 transform -translate-x-1/2 w-4 h-4 rounded-full bg-[#ffd700] z-10 hidden md:block" />

                {/* Event card */}
                <div className={`w-full md:w-[calc(100%-40px)] ${index % 2 === 0 ? 'md:mr-8' : 'md:ml-8'} pl-8 md:pl-0`}>
                  <div className="bg-black border border-[#ffd700] rounded-lg p-4 transition-all duration-300 hover:border-white">
                    <h3 className="text-white text-lg md:text-xl font-medium mb-2">
                      {event.time} - {event.title}
                    </h3>
                    <p className="text-gray-300 text-sm md:text-base">
                      {event.location}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomizedTimeline;
