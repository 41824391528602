import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import LocalLoader from '../../../components/LocalLoader';
import { BASE_URL } from '../../../constants/urls';
import QRCode from 'react-qr-code';

function MyPass() {
    const { username } = useParams();
    const { tokenState } = useContext(UserContext);
    const [token] = tokenState;
    const [registrationData, setRegistrationData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchRegisteredEvents = async () => {
            // try {
                const response = await fetch(`${BASE_URL}/events/innovex/reg-events/`, {
                    headers: {
                        'Authorization': `Token ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch registered events');
                }

                const result = await response.json();
                console.log('API Response:', result); // Debug log

                if (result.data) {
                    setRegistrationData(result.data);
                    console.log('Filtered Data:', result.data); // Debug log
                } else {
                    throw new Error('Invalid data format received');
                }
            // } catch (err) {
            //     console.error('Error:', err); // Debug log
            //     setError(err.message);
            // } finally {
                setLoading(false);
            // }
        };

        fetchRegisteredEvents();
    }, [token]);

    if (loading) return <LocalLoader />;
    if (error) return <div className="text-red-500 p-4">{error}</div>;

    return (
        <div className="min-h-screen bg-[#190024] p-8">
            <div className="max-w-4xl mx-auto">
                {registrationData && registrationData.map((registration) => {
                    // Only show QR code for Innovex event
                    if (registration.event?.event_name === 'Innovex') {
                        return (
                            <div key={registration.id} className="text-center ">
                                <div className="inline-block p-4 bg-white rounded-lg mt-20">
                                    <QRCode
                                        value="https://backend.iedctkmce.ac.in/events/innovex/reg-events"
                                        size={256} // Larger size for better visibility
                                    />
                                </div>
                                <p className="text-sm text-gray-300 mt-2">
                                    Scan this QR code at registration desk.
                                </p>
                            </div>
                        );
                    }
                    return null; // Hide all other events
                })}

                {(!registrationData || registrationData.length === 0) && (
                    <div className="text-center text-gray-400 py-8">
                        No registrations found for Innovex.
                    </div>
                )}
            </div>
        </div>
    );
}

export default MyPass;