import { useNavigate } from "react-router-dom";
import bgwork from "../assets/competitionBg.webp";
import "./EventPage.css";
import { useContext } from "react";
import { PopUpContext } from "../../../context/PopUpContext";

function InnovexWorkshop({ events, category, onClick }) {
  const navigate = useNavigate();

  const { popUpState, popUpContentState } = useContext(PopUpContext);
  const [popUp, setPopUp] = popUpState;
  const [popUpContent, setPopUpContent] = popUpContentState;

  const handleonClick = (eventId) => {
    const token = localStorage.getItem("IEDCUserToken");
    if (!token) {
      setPopUpContent({
        heading: "NOTICE",
        description: "Login to register for events.",
      });
      setPopUp(true);
    } else {
      onClick(eventId);
    }
  };

  return (
    <div className="workshops-page">
      <div className="flex flex-col gap-20 hero-section">
        <h1 className="page-title">
          {category?.toUpperCase() || localStorage.getItem("cat")}
        </h1>
        <div className="flex justify-center w-full">
          {/* <button
            className="relative bg-transparent text-black text-[12px] sm:text-[16px] hover:text-white  p-2 sm:px-4 lg:px-6  rounded-full overflow-hidden group border border-white  z-50"
            onClick={() => navigate("/innovex/reg-events")}
          >
            <span className="relative z-10">Registered Events</span>
            <span className="absolute inset-0 bg-white transform -translate-x-0 group-hover:translate-x-full transition-transform duration-300 ease-in-out"></span>
          </button>{" "} */}
        </div>
      </div>

      <div className="workshop-cards">
        {events?.length > 0 ? (
          events?.map((event) => (
            <div key={event.id} className="workshop-card">
              <div className="card-image">
                <img
                  src={event.image || bgwork}
                  alt="Workshop"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <h2>{event.event_name || "Workshop"}</h2>
              <div className="divider"></div>
              <p>
                {event.description ||
                  "Get first hand experience and learn from quality workshops which fare across a wide area of interests."}
              </p>
              <button
                className="check-out-btn"
                onClick={() => handleonClick(event.id)}
              >
                CHECK IT OUT
              </button>
            </div>
          ))
        ) : (
          <div>No {category}.</div>
        )}
      </div>
    </div>
  );
}

export default InnovexWorkshop;
