import React, { useEffect, useState } from "react";
import InnovexNavbar from "./Navbar";
import { Footer } from "../Footer";
import useWindowSize from "../../hooks/useWindowSize";
import { Route, Routes, useNavigate } from "react-router-dom";
import Innovex from "./Innovex";
import InnovexEvents from "./pages/InnovexEvents";
import InnovexWorkshop from "./pages/InnovexWorkshop";
import NotFound from "../../pages/NotFound";
import bgwork from "./assets/competitionBg.webp";
import RegisteredEvents from "./pages/InnovexRegEvents";
import InnovexEditProfile from './pages/Profile';
import ContactPage from "./pages/Innovex_Contact_Info";
import MyPass from "./pages/Pass";

export default function InnovexLayout() {
  const [width, height] = useWindowSize();
  const [workshopEvents, setWorkshopEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [category, setCategory] = useState();

  const navigate = useNavigate();

  const fetchEvents = async () => {
    try {
      const response = await fetch(
        "https://backend.iedctkmce.com/events/innovex/all-event"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setWorkshopEvents(responseData);
    } catch (error) {
      setError("Failed to load events");
    } finally {
      setIsLoading(false);
    }
  };

  const filterEvents = (e) => {
    if (e) {
      localStorage.setItem("cat", e);
      setCategory(`${e}s`);
    }
    setFilteredEvents(
      workshopEvents.filter(
        (event) => event.category === localStorage.getItem("cat")
      )
    );
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  useEffect(() => {
    filterEvents();
  }, [workshopEvents]);

  return (
    <div className="h-full w-screen">
      <div className="w-full bg-red-600 text-white text-center py-2 overflow-hidden  whitespace-nowrap">
        <marquee behavior="scroll" direction="left" className="text-lg font-bold font-mono">
          Iftar  kits will be provided for those who are fasting
        </marquee>
      </div>
      <InnovexNavbar
        windowSize={{ width, height }}
        onClick={(e) => {
          filterEvents(e);
          navigate("events");
        }}
      />
      <Routes>
        <Route path="contact-us" element={<ContactPage />} />
        <Route path="edit-profile" element={<InnovexEditProfile />} />
        <Route path="my-pass" element={<MyPass />} />
        <Route path="/" element={<Innovex events={workshopEvents} handleonClick={(id) => navigate(`workshop${id}`)} />} />
        <Route
          path="events"
          element={
            <InnovexWorkshop
              category={category}
              events={filteredEvents}
              onClick={(id) => navigate(`workshop${id}`)}
            />
          }
        />
        {workshopEvents.map((event) => (
          <Route key={event.id} path={`/workshop${event.id}`} element={<InnovexEvents event={event} />} />
        ))}
        <Route path="reg-events" element={<RegisteredEvents handleOnClick={(id) => navigate(`workshop${id}`)} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}
