import React, { useContext, useEffect, useState } from "react";
import "./EventPage.css";
import { get_profile } from "../../../apis/user";
import { UserContext } from "../../../context/UserContext";
import { useGoogleLogin } from "@react-oauth/google";
import { get_google_profile, login } from "../../../apis/auth";
import { register_event } from "../../../apis/register_event";
import { useNavigate, useParams } from "react-router-dom";
import { PopUpContext } from "../../../context/PopUpContext";
import gatewayResolver from "../../../utils/razorpayUtils";
import axios from "axios";
import { BASE_URL } from "../../../constants/urls";
import LocalLoader from "../../LocalLoader";
import indigoEllipse from "../assets/indigoEllipse.png";

function InnovexEvents({ event }) {
  const [foodIncluded, setFoodIncluded] = useState(event.foodIncluded);
  const [isTkmStudent, setIsTkmStudent] = useState(null);

  const { userState, tokenState } = useContext(UserContext);

  const [userDetails, setUserDetails] = userState;

  const { popUpState, popUpContentState } = useContext(PopUpContext);
  const [popUp, setPopUp] = popUpState;
  const [popUpContent, setPopUpContent] = popUpContentState;

  const [loading, setLoading] = useState(false);
  const [token, setToken] = tokenState;

  const [resp, setResp] = useState(0);
  const { slug } = useParams();
  const navigate = useNavigate();

  // const handleRegistration = async () => {
  //   try {
  //     const response = await fetch(
  //       `https://backend.iedctkmce.com/events/innovex/team/`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ food_included: foodIncluded }),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Network response was not ok");
  //     }

  //     const result = await response.json();
  //     // console.log('Update successful:', result);
  //     alert("Food inclusion status updated successfully!");
  //   } catch (error) {
  //     // console.error('Error updating food inclusion:', error);
  //     alert("Failed to update food inclusion status.");
  //   }
  // };

  const handleRegister = async (e) => {
    e.preventDefault();
    if (userDetails !== null) {
      if (event.is_team) {
        navigate(`/events/event_details/${slug}/create_team`);
      } else {
        setLoading(true);
        console.log("registering..");

        const payload = {
          food_included: foodIncluded ? "true" : "false",
          title: event?.event_name,
          team_leader_email: userDetails?.email_id,
          event_id: event?.id,
        };
        try {
          const response = await axios.post(
            `${BASE_URL}/events/innovex/team/`,
            payload
          );
          // console.log("register resp", response.data);

          // console.log("resStatus", response.status);

          setResp(response.status);
          setLoading(false);

          if (response.status === 201) {
            await gatewayResolver( 
              response.status === 201 ? response.data : response.errMssg,
              token,
              setUserDetails,
              setPopUpContent,
              setPopUp,
              navigate,
              slug
            );
            setPopUpContent({
              heading: "NOTICE",
              description: "Registration Successful",
            });
            setPopUp(true);
          } else if (response.status === 202) {
            setPopUpContent({
              heading: "NOTICE",
              description: "Registration Successful",
            });

            setPopUp(true);
          } else if (response.status === 400 || response.status === 406) {
            setPopUpContent({
              heading: "NOTICE",
              description: response.errMssg?.message,
            });

            setPopUp(true);
          } else if (response.status === 208) {
            setPopUpContent({
              heading: "NOTICE",
              description: "Already Registered",
            });

            setPopUp(true);
          } else {
            setPopUpContent({
              heading: "NOTICE",
              description: "Something went wrong",
            });
            setPopUp(true);
          }
        } catch (err) {
          console.log("Error occurred: ", err);
          if (err?.response?.data?.message) {
            setPopUpContent({
              heading: "NOTICE",
              description: err?.response?.data?.message,
            });
            setPopUp(true);
          }
        } finally {
          setLoading(false);
        }
      }
    } else {
      if (userDetails === null) {
        localStorage.setItem("IEDC_redirectPath", "events/event/" + slug);
        getGoogleAccount();
      } else {
        setPopUpContent({
          heading: "NOTICE",
          description: "Complete your profile!",
        });
        setPopUp(true);
      }
    }
  };

  const loginIEDC = async (credentialResponse, b) => {
    setLoading(true);
    const google_profile_response = await get_google_profile(
      credentialResponse.access_token
    );
    if (google_profile_response.status === 200) {
      const response = await login(credentialResponse.access_token);
      if (response.status === 200) {
        setToken(response.response.key);
        const profile_response = await get_profile(response.response.key);

        if (profile_response.status === 200) {
          localStorage.setItem("IEDCUserToken", response.response.key);
          setUserDetails(profile_response.response.data);
          setPopUpContent({
            heading: "NOTICE",
            description: "Login successful",
          });
          setPopUp(true);
        }
      } else {
        setPopUpContent({
          heading: "Login failed",
          description: "Login failed.",
        });
        setPopUp(true);
      }
    } else {
      setPopUpContent({
        heading: "Login failed",
        description: "Login failed.",
      });
      setPopUp(true);
    }
    setLoading(false);
  };

  const getGoogleAccount = useGoogleLogin({
    onSuccess: loginIEDC,
    scope: "https://www.googleapis.com/auth/userinfo.profile",
    onError: () => {},
  });

  const getProfile = async (usertoken) => {
    const response = await get_profile(usertoken);

    setIsTkmStudent(
      response?.response?.data?.email_id.endsWith("@tkmce.ac.in") || false
    );
    // console.log("response: ", response?.response?.data?.email_id.endsWith("@tkmce.ac.in"));
  };
  useEffect(() => {
    const token = localStorage.getItem("IEDCUserToken");
    if (token) {
      getProfile(token);
    } else {
      // alert("User not found. Please login again");
      navigate("/innovex");
    }
  }, []);

  return (
    <div className="relative event-page bg-[#190024]">
      <div className="event-container">
        <div className="event-image">
          <img src={event.image} alt={event.event_name} />
        </div>

        <h1 className="event-title">{event.event_name}</h1>

        <div className="event-description">
          {event.description || "No description available"}
        </div>

        <div className="event-details">
          <div className="event-timing">
            <div className="detail-item">
              <h3>Start date:</h3>
              <p>{new Date(event.event_date).toLocaleDateString(("en-GB"))}</p>
            </div>
            <div className="detail-item">
              <h3>End date:</h3>
              <p>
                {event.event_end
                  ? new Date(event.event_end).toLocaleDateString(("en-GB"))
                  : "TBA"}
              </p>
            </div>
            <div className="detail-item">
              <h3>Venue:</h3>
              <p>{event.event_venue}</p>
            </div>
          </div>

          {event.isTeam && (
            <div className="team-details">
              <h3>Team Size:</h3>
              <p>
                {event.minMembers} - {event.maxMembers} members
              </p>
              <h3>Maximum Teams:</h3>
              <p>{event.maxTeams}</p>
            </div>
          )}
        </div>

        <div className="registration-section">
          <h2>REGISTRATION DETAILS</h2>
          <div className="registration-details">
            <div className="detail-item">
              <h3>Start date:</h3>
              {event.reg_start
                  ? new Date(event.reg_start).toLocaleDateString(("en-GB"))
                  : "TBA"}
            </div>
            <div className="detail-item">
              <h3>End date:</h3>
              {event.reg_end
                  ? new Date(event.reg_end).toLocaleDateString(("en-GB"))
                  : "TBA"}
            </div>
            <div className="detail-item">
              {event.event_name == "Innovex" && isTkmStudent && (
                <div className="flex gap-2 detail-section">
                  <h3>Food Included:</h3>
                  <label>
                    <input
                      type="checkbox"
                      checked={foodIncluded}
                      onChange={() => setFoodIncluded(!foodIncluded)}
                    />
                    Yes
                  </label>
                </div>
              )}
              <h3>Fees:</h3>
              <p>
                ₹
                {event?.event_name == "Innovex"
                  ? isTkmStudent
                    ? 150 + (foodIncluded ? 50 : 0)
                    : 250
                  : event?.event_fee || "NIL"}
              </p>
            </div>
          </div>

          <div className="action-buttons">
            {event.is_reg_open ? (
              <button
                disabled={loading}
                className={`${
                  loading ? "bg-white" : "bg-black"
                } px-14 py-3 heading min-h-[53px]  text-white font-bold text-lg my-5 transition-all relative flex-grow md:flex-grow-0'`}
                onClick={handleRegister}
              >
                {loading ? <LocalLoader small /> : "Register"}
              </button>
            ) : (
              <button className="register-btn w-full text-center " disabled>
                Buy Ticket To Attend
              </button>
            )}
            {/* <button className="update-btn" >Update Food Inclusion</button> */}
            {event.guidelineFile && (
              <button className="guidelines-btn">
                <span>📥</span> Guidelines
              </button>
            )}
          </div>

          {event.whatsappGroup && (
            <a
              href={event.whatsappGroup}
              className="whatsapp-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              Join WhatsApp Group
            </a>
          )}
        </div>
      </div>
      <img
        alt="ellipse"
        src={indigoEllipse}
        className="absolute w- -top-[5%] -right-[10%] blur-[100px] opacity-60"
      ></img>
      <img
        alt="ellipse"
        src={indigoEllipse}
        className="absolute w-[40rem] top-[6%] left-[10%] blur-[100px] opacity-30 "
      ></img>
    </div>
  );
}

export default InnovexEvents;
