import React from 'react';
// import loadingAnimation from "../lotties/lf30_editor_3mbep6ry.json";
// import Lottie from "react-lottie";
import "./loader.css";
function Loader(props) {

    // const defaultOption = {
    //     loop: true,
    //     autoplay: true,
    //     animationData: loadingAnimation,
    //     rendererSettings: {
    //       preserveAspectRatio: "xMidYMid slice"
    //     }
    // };
    if (!props.loading)
        return null;
    return (
        <div className={`fixed top-0 left-0 w-full h-screen max-h-screen overflow-clip bg-white flex items-center justify-center z-[10000]`} >
            {/* <Lottie 
                options={defaultOption} 
                width={150}
                height={150}
                /> */}
            {/* <p>Loading</p> */}
            <div className=' overflow-clip flex items-center justify-center'>
                <div className="flex w-full h-28 flex items-center overflow-clip max-h-screen relative">
                    <svg className="h-full " >
                        <mask className="h-full" id="myMask">
                            <rect className="h-full fill" width="100%" fill="#e9e2ca" />
                        </mask>
                        <text className='text-[3.3em] ' x1="0" x2="100%" y="75" fontWeight="bold" fill="black" mask="url(#myMask)">IEDC TKMCE</text>
                    </svg>
                    <svg className="h-full w-full absolute" >
                        <text className='text-[3.3em]  Text' x1="0" x2="100%" y="75" fontWeight="bold" fill='none' stroke='black' strokeWidth={1}>IEDC TKMCE
                        </text>
                    </svg>

                </div>
            </div>

        </div>
    );
}

export default Loader;