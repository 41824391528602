import React from "react";
// import disneyImg from "../assets/disneyImg.webp";

import workshopBg from "../assets/workshopBg.webp";
import talkSessionBg from "../assets/talkSessionBg.webp";
import competitionBg from "../assets/competitionBg.webp";

export default function Page1() {
  const details = [
    {
      title: "WORKSHOPS",
      image: workshopBg,
      description:
        "Innovex 2025 brings you two must-attend workshops! The Content Creation Workshop will teach you how to craft engaging digital content and build a strong brand, while the Financial Workshop covers smart money management and investment basics. Both are hands-on, practical, and packed with insights.",
    },
    {
      title: "TALK SESSIONS",
      image: talkSessionBg,
      description:
        "Get ready for four thrilling competitions at Innovex 2025! DAWN: Dare to Win is a high-stakes, Squid Game-inspired elimination challenge. Relay Coding will push your coding speed and teamwork to the limit. Profit Equation brings a Monopoly-style twist, where business strategies determine success. Tradecraft immerses you in the world of startups, challenging you to build and scale a product. Think fast and compete to win!",
    },
    {
      title: "COMPETITIONS",
      image: competitionBg,
      description:
        "Gain fresh perspectives from Two impactful sessions; Lessons from a Rising Entrepreneur dives into the journey of a budding entrepreneur, sharing real challenges and successes. The Power of Collaboration in Entrepreneurship explores how teamwork and strategic partnerships fuel business growth. Listen, learn, and take your ideas to the next level!",
    },
  ];
  return (
    <div className="flex flex-col w-full h-full">
      {/* <div className="flex flex-col w-full items-center">
        <h1 className="text-[40px] leading-[1] text-white p-10 font-bold">
          OUR SPONSORS
        </h1>
        <div className="flex justify-evenly p-10 w-full bg-white py-32">
          <img
            src={disneyImg}
            alt="disney"
            className="w-60 h-full aspect-auto"
          ></img>
          <img src={disneyImg} alt="disney" className="w-60"></img>
          <img src={disneyImg} alt="disney" className="w-60"></img>
        </div>
      </div> */}

      <div className="flex flex-col gap-10 ">
        {details?.map((item, index) => (
          <div
            key={index}
            className="flex flex-col gap-5 sm:gap-20 w-full h-full p-5 sm:p-20  bg-no-repeat bg-right sm:bg-[length:50%] bg-[length:100%] md:text-[16px]"
            style={{
              backgroundImage: `url(${item.image})`,
              // backgroundSize: "50%",
              // backgroundRepeat: "no-repeat",
              backgroundPositionX: `${index % 2 === 0 ? "100%" : "0%"}`,
            }}
          >
            <div
              className={`flex w-full h-full ${
                index % 2 === 0 ? "justify-start" : "justify-end"
              }`}
            >
              <p className="w-96 text-slate-300">{item.description}</p>
            </div>
            <div
              className={`flex w-full ${
                index % 2 === 0 ? "justify-end" : "justify-start"
              }`}
            >
              <span className="text-[32px] sm:text-[60px] text-white font-extrabold">
                {item.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
