import React from "react";
import HeroSection from "./pages/heroSection";
import Page1 from "./pages/page1";
import CustomizedTimeline from "./Timeline"
export default function Innovex({events, handleonClick}) {
  return (
    <div className="w-full h-full bg-[#190024] text-white font-zona overflow-hidden">
      <HeroSection events={events} onClickEvent={handleonClick} />
      <Page1 />
      <CustomizedTimeline/>
    </div>
  );
}
