import React, { useContext, useEffect } from "react";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { useGoogleLogin } from "@react-oauth/google";
import { get_google_profile, login } from "../apis/auth";
import { LoaderContext } from "../context/LoaderContext";
import { AiOutlineUser } from "react-icons/ai";
import { hasGrantedAnyScopeGoogle } from '@react-oauth/google';
import { get_profile } from "../apis/user";
import { PopUpContext } from "../context/PopUpContext";
import iedclogo from '../assets/logo/iedc.png';
import "./navbar.css";
export default function NavBar({ dare2develop, sideBarShow, text_black, setSideBarShow, navBarShow, setNavBarShow, windowSize }) {

  const { tokenState, userState } = useContext(UserContext);
  const [token, setToken] = tokenState;
  const [userDetails, setUserDetails] = userState;

  const { popUpState, popUpContentState } = useContext(PopUpContext);
  const [popUp, setPopUp] = popUpState;
  const [popUpContent, setPopUpContent] = popUpContentState;

  const { loadingState } = useContext(LoaderContext);
  const [loading, setLoading] = loadingState;

  const navigate = useNavigate();
  const location = useLocation();

  const NavUi = [
    {
      name: windowSize.width < 768 ? "Home" : <img alt="Home" className={`w-6 h-10 ${text_black && "invert"}`} src="/images/navbar/iedc.png" />,
      url: "/",
    },
    {
      name: "Startups",
      url: "/startups",
    },
    {
      name: "Dare2Develop",
      url: "/dare2develop",
    },
    {
      name: "Seeds",
      url: "/seeds",
    },
    {
      name: "Stories",
      url: "/stories",
    },
    {
      name: "Team",
      url: "/team",
    },

    {
      name: "Events",
      url: "/events",
    },


    {
      name: "Contact",
      url: "/contact",
    },
  ];


  const loginIEDC = async (credentialResponse, b) => {
    setLoading(true);
    const google_profile_response = await get_google_profile(credentialResponse.access_token);
    if (google_profile_response.status === 200) {
      const response = await login(credentialResponse.access_token);
      if (response.status === 200) {


        setToken(response.response.key);
        // setPopUpContent({ heading: "NOTICE", description: "Login successfull" })
        // setPopUp(true);
        const profile_response = await get_profile(response.response.key);
        // setUserDetails(google_profile_response)

        // let userObj={ full_name: google_profile_response.response.names[0].displayName, email_id: google_profile_response.response.emailAddresses[0].value, profile_pic: google_profile_response.response.photos[0].url,profile_completed:profile_response.response.profile_completed }
        // localStorage.setItem('IEDCUser', JSON.stringify(userObj))
        if (profile_response.status === 200) {

          localStorage.setItem('IEDCUserToken', response.response.key)
          setUserDetails(profile_response.response.data);
          setPopUpContent({ heading: "NOTICE", description: "Login successful" });
          setPopUp(true);
          //navigate("/dashboard");


        }

      } else {
        setPopUpContent({ heading: "Login failed", description: "Login failed." })
        setPopUp(true);
      }
    } else {
      setPopUpContent({ heading: "Login failed", description: "Login failed." })
      setPopUp(true);
    }
    setLoading(false);
  };


  const getGoogleAccount = useGoogleLogin({
    onSuccess: loginIEDC,
    redirect_uri: 'http://localhost:3000/',
    scope: 'https://www.googleapis.com/auth/userinfo.profile',
    onError: () => {
    },
  });

  const handleSelect = (e) => {
    e.preventDefault();
    setSideBarShow(false);
    // navigate(`${route.path === "/events" ? route.path : "/dashboard" + route.path}`);

  }

  const handleChangeRoute = (to) => {
    document.body.style.overflow = "scroll";
    if (windowSize.width < 768)
      setNavBarShow(false);
    navigate(to);
  }

  useEffect(() => {
    // (async()=>{
    if (token?.length > 0 && userDetails?.profile_completed === false) {
      // setPopUpContent({ heading: "NOTICE", description: "Complete your profile" })
      // setPopUp(true);
      // navigate("/dashboard/edit_profile");# after innovex uncomment it
      navigate("/innovex/edit-profile"); 

      // <Navigate to="/dashboard/edit_profile" element={<EditProfile />} exact />
    }
    // })()
  }, [userDetails, token, location.pathname]);

  useEffect(() => {
    if (navBarShow && windowSize.width < 768) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";

    }


  }, [windowSize, navBarShow]);

  return (
    <nav className="bg-transparent w-full z-50 absolute top-0">

      <div className="flex items-center  justify-between w-full">



        {/* <!-- Mobile menu button --> */}
        <div className="flex md:hidden z-10 absolute md:relative right-2 top-2">
          <button
            onClick={() => setNavBarShow(show => !show)}
            type="button"
            className="text-gray-500  hover:text-white  focus:outline-none focus:text-gray-600 "
            aria-label="toggle menu"
          >{
              navBarShow ?
                <svg viewBox="0 0 24 24" className="w-10 h-10 fill-current mt-3 -mr-2" version="1.1"
                  xmlns="http://www.w3.org/2000/svg">
                  <line x1="1" y1="11"
                    x2="11" y2="1"
                    stroke="black"
                    strokeWidth="2" />
                  <line x1="1" y1="1"
                    x2="11" y2="11"
                    stroke="black"
                    strokeWidth="2" />
                </svg>
                :
                <div className="flex flex-col items-end group mr-2">

                  <svg viewBox="0 0 24 24" className={`w-10 h-6 fill-current ${text_black ? "text-black" : "text-white"}`}>
                    {/* <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      ></path> */}
                    <line x1="0" y1="20" x2="80" y2="20" style={{ stroke: text_black ? "black" : "white", strokeWidth: "2" }} />
                  </svg>
                  <svg viewBox="0 0 24 24" className={`w-6 h-6 fill-current ${text_black ? "text-black" : "text-white"}`}>
                    {/* <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      ></path> */}
                    <line x1="30%" y1="7" x2="80" y2="7" style={{ stroke: text_black ? "black" : "white", strokeWidth: "2" }} />
                  </svg>
                </div>
            }
          </button>
        </div>
      </div>

      {/* <!-- Mobile Menu open: "block", Menu closed: "hidden" --> */}
      <div className={`flex-1 md:flex w-full md:items-center justify-center md:justify-between absolute left-0 md:relative ${navBarShow && windowSize.width < 768 && "bg-white h-screen w-screen top-0 left-0 renderAnimate"} ${!navBarShow && windowSize.width < 768 && "hidden"} mb-5 md:bg-transparent z-100 w-full `} >



        <div className="flex flex-col h-[80vh] md:h-auto justify-evenly md:justify-center md:-mx-4 md:flex-row md:items-center mt-0 md:mx-8 w-full md:mt-2">
          {navBarShow && NavUi.map((content, index) => {
            return (
              <button onClick={() => handleChangeRoute(content.url)} key={index}
                className={`px-2 py-1  mx-2 mt-8 text-sm ${text_black || windowSize.width < 768 ? 'text-gray-500' : `${dare2develop ? "text-gray-300" : "text-gray-400"} hover:text-white`} ${windowSize.width < 768 && 'text-black'} rounded-md md:mt-0 transition-all  ${text_black ? "hover:text-black" : "hover:text-white"}`}
              >{content.name}

              </button>

            )
          })}

          <div className="flex items-center mt-8 md:my-0 px-2 mx-2 py-1">
            {navBarShow &&
              (token?.length > 0 ?
                (
                  windowSize.width < 768 ?
                    <Link className="w-full text-center  text-black " to="/dashboard" >
                      Dashboard
                    </Link>

                    :

                    <Link
                      to="/dashboard"
                      className="flex items-center focus:outline-none"
                      aria-label="toggle profile dropdown"
                    >
                      <div className={`w-8 h-8 group  overflow-hidden border-2 ${text_black ? "border-black" : `${dare2develop ? "text-gray-300" : "border-gray-400"} hover:border-white`} rounded-full font-semibold flex justify-center items-center`}>

                        {/* <img
                            src={userDetails?.profile_pic}
                            className="object-cover w-full h-full"
                            alt="avatar"
                          /> */}
                        <AiOutlineUser className={`${text_black ? "text-black" : `${dare2develop ? "text-gray-300" : "text-gray-400"} group-hover:text-white`} text-xl`} />


                      </div>
                    </Link>
                )
                :
                <button className={`${text_black ? "text-gray-500" : ""} ${windowSize.width < 768 ? "text-black" : !text_black && `${dare2develop ? "text-gray-300" : "text-gray-400"}`}  w-full text-center  rounded ${text_black ? "hover:text-black" : "hover:text-white"}`} onClick={() => getGoogleAccount()}>
                  Login
                </button>)
            }
          </div>
        </div>


      </div>


    </nav>
  );
};
