import React, { useEffect, useState } from "react";
import bgwork from "../assets/competitionBg.webp";
import axios from "axios";
import { useNavigate } from "react-router-dom";

//suzie-pie
function RegisteredEvents({ handleOnClick }) {
  const [registeredEvents, setRegisteredEvents] = useState([]);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  const fetchRegisteredEvents = async () => {
    const token = localStorage.getItem("IEDCUserToken");
    if (!token) {
      alert("User not found. Please login again.");
      navigate("/innovex");
      return;
    }
    // console.log("Token: ", token);

    try {
      const response = await axios.get(
        "https://backend.iedctkmce.com/events/innovex/reg-events/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      const responseData = await response?.data;
      setRegisteredEvents(responseData);
      // console.log(registeredEvents);
    } catch (error) {
      console.error("Error fetching registered events:", error);
      setError("Failed to load registered events");
    }
  };

  useEffect(() => {
    fetchRegisteredEvents();
  }, []);

  if (error) {
    return <div>Some error occurred. Try again later</div>;
  }

  return (
    // <div>
    //   <h2>Registered Events</h2>
    //   {registeredEvents.length === 0 ? (
    //     <p>No registered events found.</p>
    //   ) : (
    //     <ul>
    //       {registeredEvents.map(event => (
    //         <li key={event.id}>{event.title}</li>
    //       ))}
    //     </ul>
    //   )}
    // </div>

    <div className="workshops-page">
      <div className="hero-section">
        <h1 className="page-title">Registered Events</h1>
      </div>

      <div className="workshop-cards">
        {registeredEvents?.data?.length > 0 ? (
          registeredEvents?.data?.map((event) => (
            <div
              key={event?.event?.id}
              className="workshop-card"
              onClick={() => handleOnClick(event?.event?.id)}
            >
              <div className="card-image">
                <img
                  src={event?.event?.image || bgwork}
                  alt={event?.event?.event_name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "10px",
                  }}
                />
              </div>
              <h2>{event?.event?.event_name || "Workshop"}</h2>
              <div className="divider"></div>
              <p>
                {event?.event?.description ||
                  "Get first hand experience and learn from quality workshops which fare across a wide area of interests."}
              </p>
              {/* <button className="check-out-btn">CHECK IT OUT</button> */}
              <div className="flex flex-col ">
                <span>Team Leader: {event.team_leader?.name}</span>
                <span>
                  Food Included: {event?.food_included ? "Yes" : "No"}
                </span>
                <span>
                  Payment: {event?.payment_status ? "Success" : "Pending"}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div>No Registered Events.</div>
        )}
      </div>
    </div>
  );
}

export default RegisteredEvents;
