import React, { useContext, useEffect, useState } from "react";
import purpleEllipse1 from "../assets/purpleEllipse1.webp";
import pinkEllipse1 from "../assets/pinkEllipse1.webp";
import indigoEllipse from "../assets/indigoEllipse.png";
import innovexText from "../assets/innovexText.webp";
import { PopUpContext } from "../../../context/PopUpContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import bgwork from "../assets/competitionBg.webp";
import iedc_tkmce from "../assets/iedc-tkmce.png";
import iedc_tkmit from "../assets/iedc-tkm-it.png";
import iedc_tkm_arts from "../assets/iedc-tkm-arts.png";

export default function HeroSection({ events, onClickEvent }) {
  const { popUpState, popUpContentState } = useContext(PopUpContext);
  const [popUp, setPopUp] = popUpState;
  const [popUpContent, setPopUpContent] = popUpContentState;

  const [error, setError] = useState(null);
  const [isInnovexPresent, setIsInnovexPresent] = useState(true);

  const navigate = useNavigate();
  const handleRegistrationClick = () => {
    const token = localStorage.getItem("IEDCUserToken");
    if (!token) {
      setPopUpContent({
        heading: "NOTICE",
        description: "Login to register for events.",
      });
      setPopUp(true);
    } else {
      navigate("workshop262");
    }
  };
  // const events = [
  //   {
  //     title: "Title of Event",
  //     name: "Lorem Ipsum",
  //     desrciption:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  //   },
  //   {
  //     title: "Title of Event",
  //     name: "Lorem Ipsum",
  //     desrciption:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  //   },
  //   {
  //     title: "Title of Event",
  //     name: "Lorem Ipsum",
  //     desrciption:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  //   },
  // ];

  const fetchRegisteredEvents = async () => {
    const token = localStorage.getItem("IEDCUserToken");
    if (!token) {
      // alert("User not found. Please login again.");
      navigate("/innovex");
      return;
    }
    // console.log("Token: ", token);

    try {
      const response = await axios.get(
        "https://backend.iedctkmce.com/events/innovex/reg-events/",
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      const responseData = await response?.data;
      // console.log(responseData?.data);

      if (responseData?.data?.length > 0) {
        setIsInnovexPresent(
          responseData.data.some(
            (event) => event.event.event_name === "Innovex"
          )
        );
      } else {
        setIsInnovexPresent(false);
      }
      // setRegisteredEvents(responseData);
      // console.log(registeredEvents);
    } catch (error) {
      console.error("Error fetching registered events:", error);
      setError("Failed to load registered events");
    }
  };

  const handleonClick = (eventId) => {
    const token = localStorage.getItem("IEDCUserToken");
    if (!token) {
      setPopUpContent({
        heading: "NOTICE",
        description: "Login to register for events.",
      });
      setPopUp(true);
    } else {
      onClickEvent(eventId);
    }
  };

  useEffect(() => {
    fetchRegisteredEvents();
  }, []);

  return (
    <div className="relative flex flex-col justify-center font-inter text-white sm:h-screen z-50 overflow-hidden">
      <div className="flex flex-col h-full sm:h-screen sm:justify-between mt-20">
        <div className=" flex flex-col justify-center p-3 sm:justify-end items-center w-full h-1/2 z-50">
          {/* <span className="text-[200px] text-center h-full w-full p-4 stroke-text leading-[1.2]">
            INNOVEX
          </span> */}
          <div className="flex flex-col justify-between gap-5">
            <img
              src={innovexText}
              alt="innovex"
              className="w-[20rem] sm:w-[30rem] md:w-[50rem]"
            ></img>

            <div className="flex justify-evenly w-full font-bold text-[14px] sm:text-[20px]">
              <span className="relative">
                <img
                  src={iedc_tkmce}
                  alt="IEDC TKMCE"
                  className="w-[75px] sm:w-[100px]  md:w-[125px]  object-contain"
                />
                {/* <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span> */}
              </span>

              <span className="relative ">
                <img
                  src={iedc_tkmit}
                  alt="IEDC TKMIT"
                  className="w-[75px]  sm:w-[100px]  md:w-[125px]  object-contain"
                />
                {/* <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span> */}
              </span>

              <span className="relative ">
                <img
                  src={iedc_tkm_arts}
                  alt="IEDC TKM ARTS"
                  className="w-[75px] sm:w-[100px] md:w-[100px]object-contain "
                />
                {/* <span className="absolute bottom-0 left-0 w-full h-[2px] bg-white transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 ease-in-out"></span> */}
              </span>
            </div>

            {!isInnovexPresent && (
              <div className="flex justify-center w-full">
                <button
                  className="relative bg-transparent text-black text-[12px] sm:text-[16px] hover:text-white  p-2 px-6  rounded-full overflow-hidden group border border-white  z-50"
                  onClick={() => handleRegistrationClick()}
                >
                  <span className="relative z-10">Register</span>
                  <span className="absolute inset-0 bg-white transform -translate-x-0 group-hover:translate-x-full transition-transform duration-300 ease-in-out"></span>
                </button>{" "}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-center items-center h-full w-full  text-black z-50">
          <div className="flex flex-col sm:flex-row gap-3 w-full  justify-center items-center">
            {events?.length > 0 ? (
              events.slice(0, 3).map((event) => (
                <div
                  key={event.id}
                  className=" bg-[#000000] rounded-lg p-3 w-[300px] text-white h-full"
                >
                  <div className="card-ima bg-[#333] rounded-lg h-[200px] w-full">
                    <img
                      src={event.image || bgwork}
                      alt="Workshop"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "10px",
                      }}
                    />
                  </div>
                  <h2 className="font-semibold">
                    {event.event_name || "Workshop"}
                  </h2>
                  <div className="divider"></div>
                  <p className="line-clamp-2">
                    {event?.description?.slice(0, 60).concat("....") ||
                      "Get first hand experience and learn from quality workshops which fare across a wide area of interests."}
                  </p>
                  <button
                    className="check-out-btn"
                    onClick={() => handleonClick(event.id)}
                  >
                    CHECK IT OUT
                  </button>
                </div>
              ))
            ) : (
              <div>No Events</div>
            )}
          </div>
        </div>
      </div>

      <img
        alt="ellipse"
        src={indigoEllipse}
        className="absolute w- -top-[5%] -right-[10%] blur-[100px] opacity-60"
      ></img>
      <img
        alt="ellipse"
        src={indigoEllipse}
        className="absolute w-[40rem] top-[6%] left-[10%] blur-[100px] opacity-30 "
      ></img>
    </div>
  );
}
